import { IconDefinition, IconName } from "@fortawesome/fontawesome-common-types";

const prefix = "far";
const iconName = "line-nodes";
const width = 18;
const height = 18;
const aliases = ["line-drawing"];
const unicode = "f99q";
const svgPathData =
    "M 17.925 0.5 C 17.925 0.2239 17.7011 0 17.425 0 H 12.5 C 12.2239 0 12 0.2239 12 0.5 V 4.2387 C 11.9799 4.2559 11.9604 4.2739 11.9414 4.2929 L 4.2928 11.9415 C 4.2739 11.9604 4.2558 11.9799 4.2387 12 H 0.5 C 0.2239 12 0 12.2239 0 12.5 V 16.9734 C 0 17.2496 0.2239 17.4734 0.5 17.4734 H 5.425 C 5.7012 17.4734 5.925 17.2496 5.925 16.9734 V 13.1377 L 13.3556 5.7071 C 13.4265 5.6363 13.4845 5.5574 13.5296 5.4734 H 17.425 C 17.7011 5.4734 17.925 5.2496 17.925 4.9734 V 0.5 Z M 14 3.4734 V 2 H 16 V 3.4734 H 14 Z M 3.925 14 V 15.4734 H 2 V 14 H 3.925 Z";

export const LineNodesIcon: IconDefinition = {
    prefix,
    iconName: iconName as IconName,
    icon: [width, height, aliases, unicode, svgPathData],
};
