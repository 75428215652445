import { IconDefinition, IconName } from "@fortawesome/fontawesome-common-types";

const prefix = "far";
const iconName = "area-select";
const width = 14;
const height = 14;
const aliases = ["cursor-select"];
const unicode = "f99z";
const svgPathData =
    "M11.75 0.625H10C9.50781 0.625 9.125 1.03516 9.125 1.5V1.71875H3.875V1.5C3.875 1.03516 3.46484 0.625 3 0.625H1.25C0.757812 0.625 0.375 1.03516 0.375 1.5V3.25C0.375 3.74219 0.757812 4.125 1.25 4.125H1.46875V9.375H1.25C0.757812 9.375 0.375 9.78516 0.375 10.25V12C0.375 12.4922 0.757812 12.875 1.25 12.875H3C3.46484 12.875 3.875 12.4922 3.875 12V11.7812H7V10.4688H3.875V10.25C3.875 9.78516 3.46484 9.375 3 9.375H2.78125V4.125H3C3.46484 4.125 3.875 3.74219 3.875 3.25V3.03125H9.125V3.25C9.125 3.74219 9.50781 4.125 10 4.125H10.2188V6H11.5312V4.125H11.75C12.2148 4.125 12.625 3.74219 12.625 3.25V1.5C12.625 1.03516 12.2148 0.625 11.75 0.625ZM10.2188 3.03125V1.71875H11.5312V3.03125H10.2188ZM2.78125 11.7812H1.46875V10.4688H2.78125V11.7812ZM2.78125 3.03125H1.46875V1.71875H2.78125V3.03125Z M8.56283 6.875C8.56283 6.67188 8.71908 6.5 8.9222 6.5C9.00033 6.5 9.09408 6.54688 9.15658 6.59375L13.4378 10.4062C13.516 10.4688 13.5628 10.5625 13.5628 10.6562C13.5628 10.8594 13.4066 11 13.2035 11H11.3597L12.2503 12.7812C12.3753 13.0312 12.2816 13.3281 12.0316 13.4531C11.7816 13.5781 11.4847 13.4844 11.3597 13.2344L10.4535 11.4062L9.15658 12.8906C9.09408 12.9688 9.00033 13 8.90658 13C8.70345 13 8.56283 12.8594 8.56283 12.6562V6.875Z";

export const AreaSelectIcon: IconDefinition = {
    prefix,
    iconName: iconName as IconName,
    icon: [width, height, aliases, unicode, svgPathData],
};
