import { NodeSortAction } from "@iventis/domain-model/model/nodeSortAction";
import { NodeType } from "@iventis/domain-model/model/nodeType";
import { Content } from "@iventis/translations/content/typed-content";
import { FieldSort } from "@iventis/types";
import { SortMethod } from "@iventis/components";
import { Node } from "@iventis/domain-model/model/node";
import { TreeBrowserNode, TreeBrowserSort } from "@iventis/tree-browser";
import { translate } from "@iventis/translations/translation";

export const getLibrarySorts: () => TreeBrowserSort<Node>[] = () => [
    {
        name: translate(Content.library.sort.folder_first_asc),
        sort: (nodes: Node[]) => {
            const nodeFolders = nodes.filter((n) => n.type === NodeType.Folder);
            const nonFolderNodes = nodes.filter((n) => n.type !== NodeType.Folder);
            nodeFolders.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
            nonFolderNodes.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
            return nodeFolders.concat(nonFolderNodes);
        },
        field: "name",
        action: NodeSortAction.FolderFirstAsc,
    },
    {
        name: translate(Content.library.sort.name_asc),
        sort: (nodes: Node[]) => nodes.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
        field: "name",
        action: NodeSortAction.Asc,
    },
];

export const getTreeSortByFieldSort = (sort: FieldSort<NodeSortAction>, treeSorts: SortMethod[]) => treeSorts.find((s) => s.id === getTreeSortIdFromFieldSort(sort));

export const getLibrarySortByTreeSortId = <TNode extends TreeBrowserNode>(treeSortId: string, librarySorts: TreeBrowserSort<TNode>[]): TreeBrowserSort<TNode> =>
    librarySorts.find((s) => getTreeSortIdFromFieldSort(s) === treeSortId);

export const getTreeSortIdFromFieldSort = (fieldSort: FieldSort<NodeSortAction>) => `${fieldSort.field}${fieldSort.action.toString()}`;

export const getLibrarySortByFieldSort = <TNode extends TreeBrowserNode>(fieldSort: FieldSort<NodeSortAction>, librarySorts: TreeBrowserSort<TNode>[]): TreeBrowserSort<TNode> =>
    librarySorts.find((s) => s.field === fieldSort.field && s.action === fieldSort.action);

export const getTreeSortsFromLibrarySorts = <TNode extends TreeBrowserNode>(librarySorts: TreeBrowserSort<TNode>[]): SortMethod[] =>
    librarySorts.map((librarySort: TreeBrowserSort<TNode>) => ({
        displayName: librarySort.name,
        id: getTreeSortIdFromFieldSort(librarySort),
    }));

export const stringInterpolateSort = (sort: FieldSort<NodeSortAction>) => `${sort.field}${sort.action}`;

export function createRequestConfigWithSort(sort?: FieldSort<NodeSortAction>) {
    return sort == null
        ? {}
        : {
              params: { sort: stringInterpolateSort(sort) },
          };
}
