import { SelectedMapObject } from "@iventis/map-types";
import { ObjectUpdate } from "@iventis/map-engine/src/types/events-store";
import { EventStream } from "@iventis/utilities";
import { MapSitemapConfig } from "@iventis/domain-model/model/mapSitemapConfig";

export enum MapDataTableEvent {
    /** Only call once the backend has successfully created the objects */
    MapObjectsCreatedRemotely = "MapObjectsCreatedRemotely",
    /** Only call once the backend has successfully updated the objects */
    MapObjectsUpdatedRemotely = "MapObjectsUpdatedRemotely",
    /** Only call once the backend has successfully deleted the objects */
    MapObjectsDeletedRemotely = "MapObjectsDeletedRemotely",
    MapObjectsSelected = "MapObjectsSelected",
    SitemapConfigsUpdated = "SitemapConfigsUpdated",
}

export type MapObjectsCreatedRemotelyEvent = {
    type: MapDataTableEvent.MapObjectsCreatedRemotely;
    payload: ObjectUpdate[];
};

export type MapObjectsUpdatedRemotelyEvent = {
    type: MapDataTableEvent.MapObjectsUpdatedRemotely;
};

export type MapObjectsDeletedRemotelyEvent = {
    type: MapDataTableEvent.MapObjectsDeletedRemotely;
    payload: string[];
};

export type MapObjectsSelectedEvent = {
    type: MapDataTableEvent.MapObjectsSelected;
    payload: SelectedMapObject[];
};

export type SitemapConfigsUpdatedEvent = {
    type: MapDataTableEvent.SitemapConfigsUpdated;
    payload: MapSitemapConfig[];
};

/** An event stream required for keeping the data table in sync with the map (MAP --> DATA TABLE) */
export const mapDataTableEventStream = new EventStream<
    MapObjectsCreatedRemotelyEvent | MapObjectsUpdatedRemotelyEvent | MapObjectsDeletedRemotelyEvent | MapObjectsSelectedEvent | SitemapConfigsUpdatedEvent
>();
