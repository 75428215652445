import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Body3, styled } from "@iventis/styles";
import React from "react";

/**
 * A warning message component that displays a warning icon and a message.
 */
export const WarningMessageComponent = ({ message }: { message: string | React.ReactNode }) => (
    <StyledContainer>
        <FontAwesomeIcon size="sm" icon="exclamation-triangle" />
        {typeof message === "string" ? <Body3>{message}</Body3> : message}
    </StyledContainer>
);

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 5px;
    padding: 8px;
    background-color: ${({ theme }) => theme.secondaryColors.warning75};
`;
