import { NotificationResourceType } from "@iventis/domain-model/model/notificationResourceType";
import { Notification } from "@iventis/domain-model/model/notification";
import { NotificationMapExportData } from "@iventis/domain-model/model/notificationMapExportData";
import { ExportStatus } from "@iventis/domain-model/model/exportStatus";
import { NotificationResourceData } from "@iventis/domain-model/model/notificationResourceData";
import { NotificationLayersImportData } from "@iventis/domain-model/model/notificationLayersImportData";
import { NotificationLayersCopyData } from "@iventis/domain-model/model/notificationLayersCopyData";
import { NotificationMapsCopyData } from "@iventis/domain-model/model/notificationMapsCopyData";
import { MapsCopiedStatus } from "@iventis/domain-model/model/mapsCopiedStatus";

export const isNotificationInProgress = (notification: Notification<NotificationResourceData>) => {
    switch (notification.resourceType) {
        case NotificationResourceType.MapExport: {
            const data = notification.resourceData as NotificationMapExportData;
            return data.exportStatus !== ExportStatus.Completed && data.exportStatus !== ExportStatus.Failed;
        }
        case NotificationResourceType.LayersImport: {
            const data = notification.resourceData as NotificationLayersImportData;
            return !data.failed && !(data.mapLayersCreated && data.permissionsCreated);
        }
        case NotificationResourceType.LayersCopy: {
            const data = notification.resourceData as NotificationLayersCopyData;
            return !data.completed && !data.failed;
        }
        case NotificationResourceType.MapsCopy: {
            const data = notification.resourceData as NotificationMapsCopyData;
            return data.status === MapsCopiedStatus.Processing;
        }
        default:
            return false;
    }
};
