/* eslint-disable consistent-return */
/* eslint no-console: ["warn", { allow: ["warn"] }] */
import { NodeType } from "@iventis/domain-model/model/nodeType";
import { Node } from "@iventis/domain-model/model/node";
import { AppBarSubmenuItem } from "@iventis/components";
import { NODE_HEIGHT_PX, FlatNode, EMPTY_NODE_HEIGHT_PX, TreeBrowserQueryString } from "@iventis/tree-browser";
import { Authorisation } from "@iventis/domain-model/model/authorisation";
import { Status } from "@iventis/domain-model/model/status";
import { IconName, IconProp } from "@fortawesome/fontawesome-svg-core";
import { SubscriptionPlan } from "@iventis/domain-model/model/subscriptionPlan";

export interface AppBarSubMenuNode extends AppBarSubmenuItem, Node {}

const mappingLibraryOrder = [NodeType.PersonalMappingLibrary, NodeType.MappingLibrary];

const sharedLibraryNames = ["Shared library", "Shared Library", "Team workspace", "Team Workspace"];

export const createMappingLibraries = (nodeLibraries: Node[], subscriptionPlan: SubscriptionPlan): AppBarSubMenuNode[] =>
    mappingLibraryOrder.reduce((libraries, libraryType) => {
        const filteredLibraryType = nodeLibraries.filter(({ type }) => type === libraryType);
        const filterLibraries = [...libraries, ...filteredLibraryType.map((lib) => createLibraryOptionFromNode(lib))];

        return filterLibraries.map((library) =>
            sharedLibraryNames.includes(library.name) ? { ...library, upgradeNeeded: !subscriptionPlan.functionality.sharedWorkspace } : library
        );
    }, [] as AppBarSubMenuNode[]);

const createLibraryOptionFromNode = (node: Node): AppBarSubMenuNode => {
    const iconProp = getLibraryIconParameters(node);
    return {
        ...node,
        sidePanelIcon: iconProp,
        pageIcon: iconProp,
    };
};

export const getLibraryIconParameters = (node: Node): IconProp => {
    switch (node.type) {
        case NodeType.MappingLibrary:
            switch (node.id) {
                case StaticLibraryId.RecentlyViewed:
                    return { prefix: "far", iconName: "clock" };
                case StaticLibraryId.Favourites:
                    return { prefix: "far", iconName: "star" };
                default:
                    return { prefix: "far", iconName: "folders" };
            }
        case NodeType.PersonalMappingLibrary:
            return { prefix: "far", iconName: "pen-ruler" };
        default:
            console.warn("Library type not supported");
            break;
    }
};

/** Calculate the remaining height in the container, nodes with no children and that are expanded are double the height to show a message they are empty  */
export const calculateVirtualListRemainingHeightInContainer = (
    flatNodes: FlatNode<Node>[],
    exandedNodeIds: string[],
    containerHeight: number,
    nodeHeight = NODE_HEIGHT_PX,
    emptyNodeHeight = EMPTY_NODE_HEIGHT_PX
) => {
    const contentHeight = flatNodes.reduce((sum, node) => {
        const combinedNodeHeight = exandedNodeIds.includes(node.id) && node.childCount === 0 ? nodeHeight + emptyNodeHeight : nodeHeight;
        return sum + combinedNodeHeight;
    }, 0);
    return containerHeight - contentHeight;
};

/**
 * Calculates the height of a node and recursively moves through expanded children to get the total height of the node and its children
 * @param node The parent node to start recursion on
 * @param expandedNodeIds An array of all the expanded node ids in the tree
 * @param nodeHeight The height of a single given node (Defaults to constant NODE_HEIGHT_PX)
 * @param emptyNodeHeight The height of the "Node empty" text row when a folder is expanded (Defaults to constant EMPTY_NODE_HEIGHT_PX)
 * @returns The total height of the parent node and all children
 */
export const calculateHeight = (node: Node, expandedNodeIds: string[], nodeHeight = NODE_HEIGHT_PX, emptyNodeHeight = EMPTY_NODE_HEIGHT_PX) => {
    // Begin with the base height of the node
    let base = nodeHeight;
    // Check if this node is expanded
    const nodeExpanded = expandedNodeIds.includes(node.id);
    // Iterate through each child only if the node is expanded
    if (nodeExpanded) {
        node.childNodes.forEach((childN) => {
            base += calculateHeight(childN, expandedNodeIds, nodeHeight, emptyNodeHeight);
        });
    }
    // Account for the "Node empty" line
    if (nodeExpanded && node.childCount === 0) {
        base += emptyNodeHeight;
    }
    return base;
};

export enum StaticLibraryId {
    RecentlyViewed = "recently-viewed",
    Favourites = "favourites",
}

export type StaticLibrary = {
    id: StaticLibraryId;
    icon: IconName;
    translationKey: string;
};

export const staticLibraries: StaticLibrary[] = [
    { id: StaticLibraryId.Favourites, icon: "star", translationKey: "favourites" },
    { id: StaticLibraryId.RecentlyViewed, icon: "clock", translationKey: "recently_viewed" },
];

export const isStaticLibrary = (id: string) => (Object.values(StaticLibraryId) as string[]).includes(id);

export const getStaticLibraries = () => [
    {
        ...staticLibraryValues,
        id: StaticLibraryId.Favourites,
        childNodes: [],
        name: "Favourites",
    },
    {
        ...staticLibraryValues,
        id: StaticLibraryId.RecentlyViewed,
        childNodes: [],
        name: "Recently Viewed",
    },
];

export const staticLibraryValues = {
    type: NodeType.MappingLibrary,
    favourite: false,
    sourceId: "",
    authorisation: Authorisation.Admin,
    expanded: false,
    status: Status.Active,
    childCount: 0,
    createdByUserId: "",
    createdByUserName: "",
    createdAt: undefined,
    lastUpdatedByUserId: "",
    lastUpdatedByUserName: "",
    lastUpdatedAt: undefined,
};
/**
 * Creates a href that will take the user to the library and open the given nodeId
 * @param treeId - the id of the tree that the node resides in
 * @param nodeId - the id of the node that will be opened in the library, if blank will take the user to the root of the library
 * @returns - a href to open the library at the given node id
 */
export const createHrefToNode = (treeId: string, nodeId?: string) => `/spatial-planner/${treeId}${nodeId ? `?${TreeBrowserQueryString.MAIN_NODE_ID}=${nodeId}` : "/"}`;
