import { useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { AuthState, getCurrentUser, getUserPreferences } from "./auth.slice";

/** Returns [is authenticated, is loading user] */
export const useCheckUserLoggedIn = (): [boolean, boolean] => {
    const currentUser = useSelector((state: { auth: AuthState }) => state.auth.user, shallowEqual);
    const loadingUser = useSelector((state: { auth: AuthState }) => state.auth.loadingUser, shallowEqual);

    const dispatch = useDispatch<ThunkDispatch<AuthState, typeof getCurrentUser, Action<unknown>>>();
    useEffect(() => {
        const check = async () => {
            const getUserAction = await dispatch(getCurrentUser());
            if (getUserAction.meta.requestStatus === "fulfilled") dispatch(getUserPreferences({}));
        };
        check();
    }, []);

    return [currentUser !== null, loadingUser];
};
