/* eslint-disable no-console */
import { FeatureUse } from "@iventis/domain-model/model/featureUse";
import { MetricEvent } from "./metric-types";
import { usageMetricsEnabled, usageApi } from "../initialise-observability-and-monitoring";

declare const VERSION: string;

/**
 * Increments a counter metric by given amount
 */
export function incrementMonitoringCount(event: MetricEvent) {
    if (usageMetricsEnabled) {
        // Ensure usage API has been set
        if (usageApi == null) {
            console.error("Usage API has not been set");
            return;
        }

        const usageEvent: Omit<FeatureUse, "id"> = {
            usedAt: new Date(),
            name: event.name,
            quantity: event.quantity ?? 1,
            applicationVersion: VERSION,
            resourceId: event.resourceId,
            useEndedAt: new Date(),
        };

        try {
            usageApi.post("/usage", usageEvent);
        } catch (error) {
            console.error("Failed to increment monitoring count", error);
        }
    }
}
