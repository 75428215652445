import { AxiosRequestHeaders, AxiosHeaders, RawAxiosRequestHeaders } from "axios";
import { queryParams, API_KEY_QUERY_PARAM_KEY, PROJECT_ID_QUERY_PARAM_KEY } from "@iventis/utilities";

/**
 * Apply Api Key headers to each axios request from params.
 * @param required Does the axios require api keys to work? If set required and not present, error will throw.
 * @returns Any api key headers if present
 */
export const applyApiKeyHeaders = (required = false): AxiosRequestHeaders => {
    const apiKey = queryParams[API_KEY_QUERY_PARAM_KEY];
    const projectId = queryParams[PROJECT_ID_QUERY_PARAM_KEY];
    const headers: RawAxiosRequestHeaders = {};
    if (apiKey != null) {
        headers.Authorization = `Bearer ${apiKey}`;
    }

    if (projectId != null) {
        headers[PROJECT_ID_QUERY_PARAM_KEY] = projectId;
    }

    if (typeof apiKey !== "string" && required) {
        throw new Error(
            "An API key was not provided. Specify your API key in the URL with &api-key=yourapikey. Ensure yourapikey is in the base 64 equivalent of acceskey:secretaccesskey"
        );
    }

    if (typeof projectId !== "string" && required) {
        throw new Error("A project ID was not provided. Specify your project ID in the url with &project-id=yourprojectid");
    }

    return new AxiosHeaders(headers);
};
