import React from "react";
import { BottomUpHierarchy } from "@iventis/domain-model/model/bottomUpHierarchy";
import { useWindowSize } from "@iventis/utilities";
import { Node } from "@iventis/domain-model/model/node";
import { NodeType } from "@iventis/domain-model/model/nodeType";
import { InteractiveElement, fontSizes, screenSizeBreakpoints, styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { findNode } from "@iventis/tree-browser";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { useHistory } from "@iventis/subscriptions";
import { createHrefToNode } from "./mapping-library.helpers";

export const BottomUpBreadcrumbComponent = ({
    bottomUpHierarchy,
    maxCharCount = 25,
    immediateParentOnly = false,
    endingParents = 2,
    translatableContent,
}: {
    bottomUpHierarchy: BottomUpHierarchy<Node>;
    maxCharCount?: number;
    endingParents?: number;
    immediateParentOnly?: boolean;
    translatableContent?: Record<string, string>;
}) => {
    const { screenWidth } = useWindowSize();
    const translate = useIventisTranslate();

    const getName = (node: Node) => (translatableContent?.[node.name] ? translate(translatableContent[node.name]) : node.name);

    const breadcrumbStringArray: string[] = buildBreadcrumbBottomUpHierarchy(bottomUpHierarchy.parent).map((node: Node) =>
        getName(node).length > maxCharCount ? `${getName(node).substring(0, maxCharCount)}...` : getName(node)
    );

    const history = useHistory();

    const treeNode = getLibraryFromBottomUpHierarchy(bottomUpHierarchy);

    const breadcrumbString: string =
        screenWidth < screenSizeBreakpoints.large || immediateParentOnly
            ? breadcrumbStringArray[breadcrumbStringArray.length - 1]
            : breadcrumbStringArray.length <= endingParents + 2
            ? breadcrumbStringArray.join(" > ")
            : [breadcrumbStringArray[0], "...", ...breadcrumbStringArray.slice(-endingParents)].join(" > ");

    return (
        <StyledBreadcrumbContainer
            className="bottom-up-breadcrumb"
            onClick={(e) => {
                e.stopPropagation();
                history.push(createHrefToNode(treeNode.id, bottomUpHierarchy.parent.item.id));
            }}
        >
            {breadcrumbString}
        </StyledBreadcrumbContainer>
    );
};

const StyledBreadcrumbContainer = styled(InteractiveElement)`
    font-size: ${fontSizes.xSmall};
    color: ${({ theme }: { theme: Theme }) => theme.typographyColors.subdued};
    text-align: left;

    :not([disabled]) {
        color: ${({ theme }: { theme: Theme }) => theme.typographyColors.subdued};
    }

    display: flex;

    :hover {
        text-decoration: underline;
    }
`;

/**
 * Recursively build the breadcrumb list
 * @param { BottomUpHierarchy<Node> } bottomUpHierarchyIn bottomUpHierarchy
 * @param { Node[] } breadcrumbList
 * @returns { Node[] }
 */
function buildBreadcrumbBottomUpHierarchy(bottomUpHierarchyIn: BottomUpHierarchy<Node>, breadcrumbList = []) {
    // If the bottomUpHierarchy item is null, or item is project, return the breadcrumb list
    if (bottomUpHierarchyIn?.item == null || bottomUpHierarchyIn?.item.type === NodeType.Project) {
        return breadcrumbList;
    }
    // Add to start of list
    breadcrumbList.unshift(bottomUpHierarchyIn.item);
    return buildBreadcrumbBottomUpHierarchy(bottomUpHierarchyIn.parent, breadcrumbList);
}

/**
 * Goes up the tree until it finds a parent node with a bottom hierarchy present and then searches for the library.
 * @param tree The tree browser node to be searched
 * @param nodeId The node to start the search from
 * @returns the root library node
 */
export function getLibraryFromTreeWhereBottomUpHierarchyIsPresent(tree: Node & { bottomUpHierarchy?: BottomUpHierarchy<Node> }, nodeId: string): Node {
    let currentNode = null;
    do {
        currentNode = findNode([tree], currentNode != null ? currentNode.parentId : nodeId);
    } while (currentNode != null && currentNode.parentId != null && currentNode?.bottomUpHierarchy == null);

    if (currentNode == null || currentNode?.bottomUpHierarchy == null) {
        throw new Error("Failed to find bottom up hierarchy");
    }

    return getLibraryFromBottomUpHierarchy(currentNode.bottomUpHierarchy);
}

/**
 * Gets the root library item from a bottom up hierarchy
 * @param bottomUpHierarchy the bottom up hierarchy to be searched
 * @returns the root library node
 */
export function getLibraryFromBottomUpHierarchy(bottomUpHierarchy: BottomUpHierarchy<Node>): Node {
    if (bottomUpHierarchy == null) {
        throw new Error("Cant get library from null bottom up hierarchy!");
    }

    if (bottomUpHierarchy.parent != null && bottomUpHierarchy.parent.item.type !== NodeType.Project) {
        return getLibraryFromBottomUpHierarchy(bottomUpHierarchy.parent);
    }

    return bottomUpHierarchy.item;
}
